// App.js
import React from 'react';
import './App.css';
import Navbar from './Navbar';
import Press from './Press.js'
import Footer from './Footer';


function App() {
    return (
        <div className="App">
            <Navbar />
            <Press />
            <Footer />
        </div>
    );
}

export default App;
