// PrivacyPolicy.js
import React from 'react';
import caifb from "./CAI-FB-800.png";

const Footer = () => (
    <section className="footer">
        <footer>
            <p>
                <img src={caifb} alt="File Baby is a proud member of Content Authenticity Initiative"  />
            </p>
            <p>
                To inspect your content, use <a href="https://contentcredentials.org/verify" target="_blank" rel="noopener noreferrer">contentcredentials.org/verify</a>
            </p>
            <p>File Baby was created in collaboration with  <a href = "https://friendsofjustin.knowbots.org">Friends of Justin</a> </p>
            <p>
                &copy; Copyright 2024, <a href="https://my.file.baby">File Baby</a>, All Rights Reserved
            </p>

        </footer>
    </section>
);

export default Footer;


