// PrivacyPolicy.js
import React from 'react';
import FileBabyCircle from './file_baby_circle.png'
const Navbar = () => (
    <section className="navbar">

        <nav>
            <a href={"https://file.baby"}><img src={FileBabyCircle} title="File Baby Home" alt={"File Baby Home"}/></a>
            <h1>Claim Your Files at File Baby, Your Content Authenticity and Provenance Platform</h1>
        </nav>
    </section>
);

export default Navbar;


