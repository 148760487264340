import React from 'react';
import './Press.css';
import PressIcon from "./press_icon_signed.png";
import PDFIcon from "./pdf_icon.png"
import InspectIcon from "./file_baby_cr.gif"
import PressRelease1 from "./File Baby Launch Press Release.pdf"
import PressRelease2 from "./ FOR IMMEDIATE RELEASE - Love More - File Baby -BAANG.pdf"

const Press = () => {
    // Assuming fileUrl and fileName are passed as props to this component
    return (
        <div className="press-resources">
            <div><img src={PressIcon} alt={"Press Badge icon"} className={"pressicon"}/></div><h2>Press Resources</h2>
            <div className="files">
                <div className="file-item">
                    <a href={PressRelease1} target="_blank" rel="noopener noreferrer">
                        <img className={"pdficon"} src={PDFIcon} alt={"PDF Icon"}/>
                    </a>
                    {/* Add the file name as a link below the image */}
                    <a href={PressRelease1} target="_blank" rel="noopener noreferrer" className="file-name">
                        February 14, 2024, File Baby Launch Press Release
                    </a>
                </div>
                <div className="file-item">
                    <a href="http://tinyurl.com/file-baby-press-kit-02-14-2024" target="_blank" rel="noopener noreferrer">
                        <img className={"inspectIcon"} src={InspectIcon} alt={"PDF Icon"}/>
                    </a>
                    {/* Add the file name as a link below the image */}
                    <a href="http://tinyurl.com/file-baby-press-kit-02-14-2024" target="_blank" rel="noopener noreferrer" className="file-name">
                        February 14, 2024, File Baby Launch Press Kit with Verifiable Content Credentials
                    </a>
                </div>
                <div className="file-item">
                    <a href={PressRelease2} target="_blank" rel="noopener noreferrer">
                        <img className={"pdficon"} src={PDFIcon} alt={"PDF Icon"}/>
                    </a>
                    {/* Add the file name as a link below the image */}
                    <a href="https://press.file.baby/static/media/%20FOR%20IMMEDIATE%20RELEASE%20-%20Love%20More%20-%20File%20Baby%20-BAANG.0123f098660da472dcc4.pdf" target="_blank" rel="noopener noreferrer" className="file-name">
                        February 23, 2024, Love More Records Artist BAANG Claims First to Claim Music on File Baby
                    </a>
                </div>
                <div className="file-item">
                    <a href="http://tinyurl.com/press-kit-file-baby-bang" target="_blank" rel="noopener noreferrer">
                        <img className={"inspectIcon"} src={InspectIcon} alt={"PDF Icon"}/>
                    </a>
                    {/* Add the file name as a link below the image */}
                    <a href="http://tinyurl.com/press-kit-file-baby-bang" target="_blank" rel="noopener noreferrer" className="file-name">
                        February 23, 2024, File Baby Press Kit with BAANG at Love More Records with Verifiable Content Credentials
                    </a>
                </div>
                <div className="file-item">
                    <a href="http://tinyurl.com/BAANG-file-baby2" target="_blank" rel="noopener noreferrer">
                        <img className={"inspectIcon"} src={InspectIcon} alt={"PDF Icon"}/>
                    </a>
                    {/* Add the file name as a link below the image */}
                    <a href="http://tinyurl.com/BAANG-file-baby2" target="_blank" rel="noopener noreferrer" className="file-name">
                        February 23, 2024, Additional photos of BAANG and File Baby at Love More Records, with Verifiable Content Credentials
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Press;
